import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import '../Assets/css/general/product.css'
import Navbar from '../Atoms/navbar';
import productjson from "../Assets/json/products.json"

export default function Product({lg}){
  var scooters=productjson[0].scooters;
  var motorcycles=productjson[0].motorcycles;
  var mopeds=productjson[0].mopeds;
  var evs=productjson[0].electric;

  return(
    <>
      <Navbar lg={lg}/>
      <div className='product'>
      <div className="welcome_heading pt-4">
        <p>Our products</p>
      </div>
        <Tabs className="tabs" selectedTabClassName="tabsprob react-tabs__tab--selected">
          <TabList className="tablist">
            <Tab>Scooters</Tab>
            <Tab>Motorcycles</Tab>
            <Tab>Mopeds</Tab>
            <Tab>Electric</Tab>
          </TabList>
      
          <TabPanel>
            <div className="my-5 row col-lg-12 col-md-12 col-sm-12 d-flex justify-content-around mx-3">
              {scooters && scooters.map((item) =>{
                return(
                  <div className="card col-lg-5 col-md-5 col-sm-5 m-3">
                    <img src={item.img} className="card-img-top" alt="sales"/>
                    <div className="card-body">
                      <h4 className="card-text">{item.name}</h4>
                      <div className='spec'><b>Engine Capacity</b> : {item.engcap}</div>
                      <div className='spec'><b>Power</b> : {item.power}</div>
                      <div className='spec'><b>Weight</b> : {item.weight}</div>
                      <div className='pricespec'><b>Ex-Showroom</b> : <span className="pricered">₹ {item.price}</span> onwards</div>
                    </div>
                  </div>
                );
              })}
            </div>
          </TabPanel>
          <TabPanel>
          <div className="my-5 row col-lg-12 col-md-12 col-sm-12 d-flex justify-content-around mx-3">
              {motorcycles && motorcycles.map((item) =>{
                return(
                  <div className="card col-lg-5 col-md-5 col-sm-5 m-3">
                    <img src={item.img} className="card-img-top" alt="sales"/>
                    <div className="card-body">
                      <h4 className="card-text">{item.name}</h4>
                      <div className='spec'><b>Engine Capacity</b> : {item.engcap}</div>
                      <div className='spec'><b>Power</b> : {item.power}</div>
                      <div className='spec'><b>Weight</b> : {item.weight}</div>
                      <div className='pricespec'><b>Ex-Showroom</b> : <span className="pricered">₹ {item.price}</span> onwards</div>
                    </div>
                  </div>
                );
              })}
            </div>
          </TabPanel>
          <TabPanel>
          <div className="my-5 row col-lg-12 col-md-12 col-sm-12 d-flex justify-content-around mx-3">
              {mopeds && mopeds.map((item) =>{
                return(
                  <div className="card col-lg-5 col-md-5 col-sm-5 m-3">
                    <img src={item.img} className="card-img-top" alt="sales"/>
                    <div className="card-body">
                      <h4 className="card-text">{item.name}</h4>
                      <div className='spec'><b>Engine Capacity</b> : {item.engcap}</div>
                      <div className='spec'><b>Power</b> : {item.power}</div>
                      <div className='spec'><b>Weight</b> : {item.weight}</div>
                      <div className='pricespec'><b>Ex-Showroom</b> : <span className="pricered">₹ {item.price}</span> onwards</div>
                    </div>
                  </div>
                );
              })}
            </div>
          </TabPanel>
          <TabPanel>
          <div className="my-5 row col-lg-12 col-md-12 col-sm-12 d-flex justify-content-around mx-3">
              {evs && evs.map((item) =>{
                return(
                  <div className="card col-lg-5 col-md-5 col-sm-5 m-3">
                    <img src={item.img} className="card-img-top" alt="sales"/>
                    <div className="card-body">
                      <h4 className="card-text">{item.name}</h4>
                      <div className='spec'><b>Range (ECO)</b> : {item.range}</div>
                      <div className='spec'><b>Battery Capacity</b> : {item.battery}</div>
                      <div className='spec'><b>Top Speed</b> : {item.speed}</div>
                      <div className='pricespec'><b>Ex-Showroom</b> : <span className="pricered">₹ {item.price}</span> onwards</div>
                    </div>
                  </div>
                );
              })}
            </div>
          </TabPanel>
        </Tabs>
      </div>
    </>
  );

} 