import React, { useState } from 'react';  
import Home from "./Components/homepage";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import "./Assets/css/general/app.css"
import Footer from './Molecules/footer';
import Finance from './Components/finance';
import Insurance from './Components/insurance';
import Service from './Components/service';
import Product from './Components/products';
const lang=[
    {
      "name":"ENG",
      "select":true
    },
    {
      "name":"KAN",
      "select":false
    }
  ]
function App() {
  const [lg, setLg]=useState("ENG");
 
  function langsel(val){
    for(var i=0;i<lang.length;i++){
      if(lang[i].name===val){
        lang[i].select=true;
      }
      else{
        lang[i].select=false;
      }
    }
    setLg(val)
  }

  
  return (
    <div className="App">
      <div className="topdiv d-flex px-5">
        <div className="langcont d-flex justify-content-end">
          Language: 
          <select name="lang" className='langdd' onChange={(event)=>{langsel(event.target.value)}}>
            {lang && lang.map((item) =>{
              if(item.select===true)
              return(
                <>
                  <option value={item.name} className='p-2 center' selected>{item.name}</option>
                </>
              );
              else
              return(
                <>
                  <option value={item.name} className='p-2 center'>{item.name}</option>
                </>
              );
            }
            )}
          </select>
        </div>
      </div>
      <Router>
        <Routes>
          <Route path="/" element={<Home lg={lg}/>} />
          <Route path="/finance" element={<Finance lg={lg}/>} />
          <Route path="/insurance" element={<Insurance lg={lg}/>} />
          <Route path="/service" element={<Service lg={lg}/>} />
          <Route path="/products" element={<Product lg={lg}/>} />
        </Routes>
      </Router>
      <Footer lg={lg}/>
    </div>
  );
}

export default App;
