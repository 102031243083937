import "../Assets/css/general/form.css"
import Navbar from "../Atoms/navbar";
import financejson from "../Assets/json/insurance.json"
import contactjson from "../Assets/json/contact.json"

export default function Insurance({lg}){


  return(
    <>
      <Navbar lg={lg}/>
      <div className="formopt">
      <div className="welcome_heading pt-4">
        <p>{financejson[0][lg][0].financeheading}</p>
      </div>
        <div className="form row py-2 formcont">
          <div className="col-lg-6 px-5 form-text py-3">
            <p>{financejson[0][lg][0].desc1}</p>
            <p>{financejson[0][lg][0].desc2}</p>
            <p className="listhead">{financejson[0][lg][0].listhead}</p>
            <ol>
              <li>{financejson[0][lg][0].li1}</li>
              <li>{financejson[0][lg][0].li2}</li>
              <li>{financejson[0][lg][0].li3}</li>
              <li>{financejson[0][lg][0].li4}</li>
              <li>{financejson[0][lg][0].li5}</li>
            </ol>
          </div>
          <div className="form-field-section col-lg-6 px-5">
            <div className="form-head">{contactjson[0][lg][0].heading}</div>
            <form id="contact-form" className="contact-form" action="insurance.php" method="post">
              <input type="text" name="name" placeholder={contactjson[0][lg][0].field1} className="form-control input-focus-none mt-4" id="InputName" required/>
              
              <input type="text" name="number" placeholder={contactjson[0][lg][0].field2} className="form-control mt-4" id="InputMob" required/>
              
              <input type="email" name="email" placeholder={contactjson[0][lg][0].field3} className="form-control mt-4" id="InputEmail"/>
              
              <input type="text" name="usersubject" placeholder={contactjson[0][lg][0].field4} className="form-control mt-4" id="InputSubject" />
              <textarea id="InputMessage" className="form-control mt-4" name="usermessage" rows="4" placeholder={contactjson[0][lg][0].field5}/>
              <button type="submit" className="btn btn-warning mb-3 mt-4 px-4 form-btn" name="submit">{contactjson[0][lg][0].button}</button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}