import Navbar from "../Atoms/navbar";
import Hero from "../Molecules/hero";
import Review from "../Molecules/reviews";
import YoutubeEmbed from "../Molecules/tvsvideo";
import Welcome from "../Molecules/welcome";

export default function Home({lg}) {
  return (
    <div className="home">
      <Navbar lg={lg}/>
      <Hero lg={lg}/>
      <Welcome lg={lg}/>
      <YoutubeEmbed lg={lg}/>
      <Review lg={lg}/>
    </div>
  );
}
