import { Fade,Zoom } from "react-awesome-reveal";
import "../Assets/css/homepage/welcome.css"
import safety from "../Assets/images/safety.jpg"
import parts from "../Assets/images/parts.jpg"
import sales from "../Assets/images/sales.jpg"
import service from "../Assets/images/service.jpg"
import welcomejson from "../Assets/json/welcome.json"

export default function Welcome({lg}) {
  return (
    <div className="welcome">
      <Fade direction="up" delay={200}>
        <div className="welcome_heading">{welcomejson[0][lg][0].welocome}</div>
      </Fade>
      <Fade direction="up" delay={-200}>
        <p className="m-2">{welcomejson[0][lg][0].welcomedesc}
        </p>
      </Fade>
      <Zoom delay={-200}>
        <div className=" my-5 row col-lg-12 col-md-12 col-sm-12 d-flex justify-content-around">
          <div className="card col col-lg-3 col-md-5 col-sm-5">
            <img src={sales} className="card-img-top" alt="sales"/>
            <div className="card-body">
              <h5 className="card-text">{welcomejson[0][lg][0].sales}</h5>
            </div>
          </div>

          <div className="card col-lg-3 col-md-5 col-sm-5">
            <img src={safety} className="card-img-top" alt="safety"/>
            <div className="card-body">
              <h5 className="card-text">{welcomejson[0][lg][0].safety}</h5>
            </div>
          </div>

          <div className="card col-lg-3 col-md-5 col-sm-5">
            <img src={parts} className="card-img-top" alt="parts"/>
            <div className="card-body">
              <h5 className="card-text">{welcomejson[0][lg][0].parts}</h5>
            </div>
          </div>

          <div className="card col-lg-3 col-md-5 col-sm-5">
            <img src={service} className="card-img-top" alt="service"/>
            <div className="card-body">
              <h5 className="card-text">{welcomejson[0][lg][0].service}</h5>
            </div>
          </div>
        </div>
      </Zoom>
    </div>
  );
}
